import { Injectable } from '@angular/core';
import { Task } from 'app/models/task-model';
import { comment } from 'app/models/user-model';
import { EntServiceService } from './ent-service.service';
import { InitialiseService } from './initialise.service';
import { ProjServiceService } from './proj-service.service';
import { UserDataService } from './user-data.service';
import { companyStaff } from 'app/models/enterprise-model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  task: Task;
  notice: any;
  diaryTask: any;
  tasksComments: comment[];
  lastCommentBy: comment;
  staff: companyStaff[];
  constructor(private is: InitialiseService, private es: EntServiceService, public usd: UserDataService, public ps: ProjServiceService) {
    this.task = is.getTask();
    this.diaryTask = is.getTask();
  }

  taskComments(task) {
    this.usd.afs.collection<comment>(`tasks/${task.id}/comments`, ref => ref.orderBy('createdOn', 'desc')).valueChanges()
      .subscribe(coms => {
        this.tasksComments = coms;
        this.lastCommentBy = coms[coms.length - 1];
      });
  }

  
  getEntStaff(task) {
    // if (task.companyId !== undefined) {
      if (task.companyId) {
      this.usd.afs.collection<companyStaff>(`Enterprises/${task.companyId}/Participants`,
        ref => ref.orderBy('name', 'asc')).valueChanges()
        .subscribe(staff => {
          this.staff = staff;
          // console.log(staff);
        });
    } else { }
  }

  getData() {
    let tasks;
    if (this.is.toolSet === 'Prt') {
      tasks
    } else if (this.is?.toolSet === 'Prs') {
      tasks
    } else if (this.is?.toolSet === 'Cmp') {
      tasks
    } else if (this.is?.toolSet === 'Champ') {
      tasks
    } else if (this.is?.toolSet === 'Team') {
      tasks
    } else if (this.is?.toolSet === 'Cnt') {
      tasks
    } else if (this.is?.toolSet === 'Ent') {
      tasks
    } else if (this.is?.toolSet === 'Stf') {
      tasks
    } else if (this.is?.toolSet === 'Dpt') {
      tasks
    } else if (this.is?.toolSet === 'Cst') {
      tasks
    } else if (this.is?.toolSet === 'Man') {
      tasks
    } else if (this.is?.toolSet === 'Dpt') {
      tasks
    } else if (this.is?.toolSet === 'Team') {
      tasks
    }
  }

  addSelf() {
    this.is.showEmaiCheck = true;
    this.is.postpendHours = false;
    this.usd.AddEquip = false;
    this.usd.selectedHiredEquipment = [];
    this.usd.selectedEquipment = [];
    // this.newAss = this.is.initAsset();
    // this.hiredAss = this.is.initAsset();
    this.is.newAsset = this.is.initAsset();
    this.is.hiredAsset = this.is.initAsset();
    this.usd.addCompAssPlg = true;
    this.usd.newHiredAsset = false;
    this.is.classification = this.is.getClassInit();
    this.usd.task = {
      name: '', description: '', update: '', champion: {
        name: '', phoneNumber: '', by: '', byId: '', createdOn: '',
        bus_email: '', id: '', department: '', departmentId: '', photoURL: '', address: '', nationalId: '', nationality: '',
        hierarchy: '', supervisor: { name: '', id: '' }
      }, projectName: '', supervisor: { id: '', name: '' }, department: '', startTime: '', finishTime: '',
      rate: '', type: '', UpdatedOn: '', selectedWeekWork: false, selectedWork: false, departmentId: '', startDate: '',
      endDate: '', by: '', createdOn: '', projectId: '', byId: '', projectType: '', toDos: [], companyName: '', companyId: '',
      trade: '', section: this.usd.task.section, complete: false, id: '', participants: [], status: '', docs: [], selectedWeekly: false,
      classification: null, unit: '', workStatus: '', targetQty: '', actualData: [], actualQty: 0, amount: 0
    };
    this.usd.task.champCompany = { name: '', id: '' };
    if (this.is.toolSet === 'Personal') {
      const index = this.usd?.tasksChamps?.findIndex(man => man.id === this.usd.acc.id)
      if (index > -1) { } else {
        if (this.usd.tasksChamps?.length === 0) {
          this.usd.tasksChamps = [this.usd.acc];
        } else {
          this.usd.tasksChamps.push(this.usd.acc);
        }
      }
      this.is.classification = this.is.getClassInit();
      this.usd.task.champion = this.usd.acc;
      if (this.is.chatSet) {
        this.pageChampion();
      }
      console.log(this.usd.task.champion);
    } else if (this.is?.toolSet === 'Enterprise') {
      this.usd.task.companyId = this.usd.setCompany.id;
      this.usd.task.companyName = this.usd.setCompany.name;
      this.usd.task.champion = this.is.companystaff;
      if (this.is.chatSet) {
        // this.pageChampion();
        // }
        console.log(this.usd.task.champion);
      }
    } else if (this.is?.toolSet === 'Project') {
      this.usd.task.projectId = this.usd.setProject.id;
      this.usd.task.projectName = this.usd.setProject.name;
      this.usd.task.companyId = this.usd.setProject.companyId;
      this.usd.task.companyName = this.usd.setProject.companyName;
      this.usd.task.champion = this.is.companystaff;
      if (this.is.chatSet) {
        // this.pageChampion();
      }
      console.log(this.usd.task.champion);
      // }
    } else if (this.is?.toolSet === 'proj-team') {
      console.log('proj-team');
      this.is.setCompany = { name: this.usd.setProject.companyName, id: this.usd.setProject.companyId };
      this.usd.task.champCompany = this.usd.projectTeam;
      const indx = this.usd.enterprises.findIndex(cmp => cmp.id === this.usd.projectTeam.id);
      if (indx > -1) {
        this.usd.showChampSelect = true;
        this.ps.getTeamLabour(this.usd.projectTeam).subscribe(labour => {
          this.is.teamLabour = labour;
        })
      } else {
        // this.usd.showChampSelect = false;
        this.usd.showChampSelect = true;
        this.is.teamLabour = this.usd.projectTeam?.managers;
      }
      this.usd.task.department = '';
      this.usd.task.departmentId = '';
      this.usd.task.projectId = this.usd.setProject.id;
      this.usd.task.projectName = this.usd.setProject.name;
      this.usd.task.companyId = this.usd.setProject.companyId;
      this.usd.task.companyName = this.usd.setProject.companyName;
      this.usd.task.champion = this.is.companystaff;
      if (this.is.chatSet) {
        // this.pageChampion();
      }
      console.log(this.usd.task.champion);
      // }
    } else if (this.is?.toolSet === 'client') {
      this.usd.task.champCompany = { name: this.usd.setClient?.name, id: this.usd.setClient?.id }
      this.usd.task.companyId = this.usd.setCompany.id;
      this.usd.task.companyName = this.usd.setCompany.name;
      this.usd.task.champion = this.is.companystaff;
      if (this.is.chatSet) {
        // this.pageChampion();
      }
      console.log(this.usd.task.champion);
    }
    // this.champion = { name: '', email: '' };
    this.usd.champion = { name: '', email: '' };
    this.is.showColUsers = true; this.is.showEmaiCheck = true; this.is.newUnitfrm = false;
    this.is.fromClient = false;
    this.is.unit = { name: '', id: '' };
  }

  pageChampion() {
    this.usd.task.champion = this.usd.setContact;
  }
}
