import { Injectable, Optional, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
// import { FirebaseAuthService } from './this.usd.firebase-auth.service';
import { Router } from '@angular/router';
import { map, tap, switchMap, flatMap } from 'rxjs/operators';
import { Observable, combineLatest, of, merge } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChatBaseService } from './chat-base.service';
import { Message } from '../models/message';
import { Chat } from '../models/chat';
import { ServicesConfig } from './services-config';
import * as moment from 'moment';
// import * as this.usd.firebase from 'this.usd.firebase/app';
// import 'this.usd.firebase/firestore';
import { User } from '../models/user';
import { UserDataService } from './user-data.service';
import { InitialiseService } from './initialise.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class ChatService extends ChatBaseService {
  userDictionary = {};
  contact: any;
  chat$: any;

  @ViewChild('template1') template1: TemplateRef<any>;
  @ViewChild('template2') template2: TemplateRef<any>;
  modalRef: any;
  modalRef2: any;
  constructor( /* private afs: AngularFirestore, private auth: FirebaseAuthService, */ private router: Router, public usd: UserDataService,
    public is: InitialiseService, public modalService: NgbModal, @Optional() config?: ServicesConfig) {
    super(config);
    this.contact = this.is.initContact();
  }

  getHistory(chatId: string): Observable<any> {
    this.findMyChats();
    if (chatId !== undefined) {
      this.clearMsgCount(this.usd.setContact.id);
      return this.chatHistory(chatId);
    } else if(this.usd.setContact.chatId === undefined) {
      this.usd.afs.doc(`Users/${this.usd.setContact.id}/contacts/${this.usd.acc?.id}`).ref.get().then(userRef => {
        if (userRef.exists && userRef.data().chatId) {
          const chatID = userRef.data().chatId;
          this.usd.afs.doc(`Users/${this.usd.acc?.id}/contacts/${this.usd.setContact.id}`).update({'chatId': chatID}).then(() => {
            this.usd.setContact.chatId = chatID;
            return this.getHistory(chatID);
          })
        }
      });
    } else {
      // participants
      this.create(this.usd.setContact, 'noLink');
    }
  }

  findMyChats() {
    console.log([this.usd.setContact.id, this.usd.userId]);
    const docRef = this.usd.afs.collection('chats', ref => ref.where('classes', 'array-contains', [this.usd.setContact.id, this.usd.userId]));
    docRef.valueChanges().subscribe(m => {
      console.log(m);
    });
  }

  chatHistory(chatId) {
    return this.usd.afs.doc<Chat>(`chats/${chatId}`).snapshotChanges().pipe(
      map(doc => {
        const data: any = doc.payload.data();
        // const exist = data.participants.find(x => x === )
        if ((data.participants[0] === this.usd.acc.id && data.participants[1] === this.usd.setContact.id) || (data.participants[0] === this.usd.setContact.id && data.participants[1] === this.usd.acc.id)) {
          return { id: doc.payload.id, ...data };
        } else {
          this.create(this.usd.setContact, 'noLink');
        }
      })
    );
  }

  async create(part, link): Promise<boolean> {
    // Fetch user and wait for result
    const { name } = await this.usd.acc;
    console.log(name);
    const { id } = await this.usd.acc;
    const userRef = this.usd.afs.doc(`Users/${id}/contacts/${part.id}`);
    const partRef = this.usd.afs.doc(`Users/${part.id}/contacts/${this.usd.acc?.id}`);
    const docRef = this.usd.afs.collection('chats');
    console.log(id);
    const data: Chat = {
      createdAt: this.usd.firebase.firestore.Timestamp.now(),
      count: 0,
      messages: [],
      participants: [this.usd.acc?.id, part.id],
      ownerId: id,
      typing: []
    };
    const newContact = {
      email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id,
      chatId: '', phoneNumber: this.usd.acc.phoneNumber
    };
    const prtContact = {
      email: part.email, bus_email: part.bus_email, name: part.name, id: part?.id,
      chatId: '', phoneNumber: part.phoneNumber
    };
    console.log(data);
    console.log(this.usd.acc);
    return userRef.ref.get().then(usr => {
      if (usr.exists) {
        const usData = usr.data();
        this.assignDetails(usData, link)
        console.log('Chat exists', usData);
        if (usData.chatId) {
          this.addContact(docRef, usData, data, link, usr);
        } else {
          docRef.add(data).then(dat => {
            newContact.chatId = dat.id;
            partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat updated')).catch(er => {
              console.log(newContact);
              partRef.ref.get().then(prt => {
                if (prt.exists) { } else {
                  partRef.set(newContact).then(() => console.log('Chat Updated'));
                }
              })
            })
            userRef.update({ 'chatId': dat.id }).then(() => {
              // Route to new chat in chat component
              console.log('Chat created');
              return this.openChat(dat, link);
            });
          })
        }
        return this.openChat(usData, link);
      } else {
        partRef.ref.get().then(prt => {
          if (prt.exists) {
            const usData = prt.data();
            this.assignDetails(part, link)
            console.log('Chat exists', usData);
            if (usData.chatId) {
              this.doAdds(docRef, usData, data, link, prt);
              prtContact.chatId = usData.chatId;
              console.log(prtContact);
              userRef.ref.get().then(prt => {
                if (prt.exists) { } else {
                  userRef.set(prtContact).then(() => console.log('Chat Updated'));
                }
              })
            } else {
              docRef.add(data).then(dat => {
                partRef.update({ 'chatId': dat.id }).then(() => console.log('Chat updated')).catch(er => { })
                userRef.update({ 'chatId': dat.id }).then(() => {
                  // Route to new chat in chat component
                  console.log('Chat created');
                  return this.openChat(dat, link);
                });
              })
            }
          } else {
            // Add new chat data to firestore and wait for result
            const newContact = {
              email: part.email, bus_email: part.bus_email, name: part.name, id: part.id, chatId: '', phoneNumber: part.phoneNumber
            };
            const myContact = {
              email: this.usd.acc.email, bus_email: this.usd.acc.bus_email, name: this.usd.acc.name, id: this.usd.acc?.id,
              chatId: '', phoneNumber: this.usd.acc.phoneNumber
            };
            this.elseCreate(docRef, userRef, partRef, data, newContact, myContact, link);
          }
        })
      }
    })
  }

  assignDetails(part, link) {
    if (link === 'link') {
      this.usd.setContact = part;
      this.usd.setContact.link = 'link';
      console.log(this.usd.setContact.name, link);
    } else {
      this.is.chatSet = true;
      this.usd.setContact = part;
      this.usd.setContact.link = '';
    }
  }

  addContact(docRef, usData, data, link, usr) {
    docRef.doc(usData.chatId).ref.get().then(cht => {
      if (cht.exists) {
        console.log(cht.id, cht.data());
        const chatData = cht.data()
        chatData.chatId = usData.chatId;
        if (!cht.id) {
          cht.data().id = usData.chatId;
          docRef.doc(usData.chatId).update({ 'id': usData.chatId, 'chatId': usData.chatId })
        }
        if (!usr.exists) {
          const other = cht.data().participants.find(ur => ur !== this.usd.userId)
          this.usd.afs.doc(`Users/${this.usd.userId}/contacts/${other}`).ref.get().then(yuw => {
          })
        }
        return this.openChat(chatData, link);
      } else {
        docRef.add(data).then(dft => {
          const idi = dft.id;
          docRef.doc(idi).update({ 'id': idi, 'chatId': idi }).then(() => {
            return this.openChat(dft, link);
          })
        })
      }
    })
  }

  doAdds(docRef, usData, data, link, prt) {
    docRef.doc(usData.chatId).ref.get().then(cht => {
      if (cht.exists) {
        console.log(cht.id, cht.data());
        if (!cht.id) {
          cht.data().id = usData.chatId;
          docRef.doc(usData.chatId).update({ 'id': usData.chatId })
        }
        if (!prt.exists) {
          const other = cht.data().participants.find(ur => ur === this.usd.userId)
          this.usd.afs.doc(`Users/${usData.id}/contacts/${other}`).ref.get().then(yuw => {
            console.log('My doc added to his contacts');
          })
        }
        return this.openChat(cht.data(), link);
      } else {
        docRef.add(data).then(dft => {
          const idi = dft.id;
          docRef.doc(idi).update({ 'chatId': idi }).then(() => {
            return this.openChat(dft, link);
          })
        })
      }
    })
  }

  openChat(data, link) {
    if (link === 'link') {
      this.is.chatSet = true;
      if(data?.chatId){
        return this.router.navigate(['chats', data.chatId]);
      }
    } else {
      this.is.chatSet = true;
      this.usd.setContact.link = '';
    }
  }

  elseCreate(docRef: AngularFirestoreCollection<any>, userRef: AngularFirestoreDocument<any>, partRef, data, part, myContact, link) {
    console.log('elseCreate');
    docRef.add(data).then(dat => {
      part.chatId = dat.id;
      docRef.doc(part.chatId).update({ 'chatId': part.chatId });
      partRef.set(part).then(() => console.log('my contact set')).catch(er => { console.log(er) });
      userRef.set(part).then(() => {
        // Route to new chat in chat component
        console.log('Chat created', part.chatId);
        return this.openChat(part, link);
      }).catch(error => {
        console.log(error); userRef.set(part);
        return this.openChat(dat, link);
      });
    })
  }

  async sendIsTyping(chatId: string): Promise<void> {
    const { id } = await this.usd.acc;
    if (id) {
      const ref = this.usd.afs.collection('chats').doc(chatId);
      return ref.update({
        typing: this.usd.firebase.firestore.FieldValue.arrayUnion(id)
      });
    }
  }

  async deleteIsTyping(chatId: string): Promise<void> {
    const { id } = await this.usd.acc;
    if (id) {
      const ref = this.usd.afs.collection('chats').doc(chatId);
      return ref.update({
        typing: this.usd.firebase.firestore.FieldValue.arrayRemove(id)
      });
    }
  }

  async sendMessage(chatId: string, content: string): Promise<void> {
    const { id } = await this.usd.acc;
    const flag = {
      status: 'new',
      fromId: id
    };
    const data = {
      id,
      content,
      createdAt: this.usd.firebase.firestore.Timestamp.now()
    };
    const other = this.chat$.participants.find(ur => ur !== this.usd.userId)
    // console.log(data);
    if (id) {
      // const ref = this.usd.afs.collection('chats').doc(chatId);
      const path = `chats/${chatId}`
      const ref = this.usd.afs.doc<any>(path);
      return ref.update({
        messages: this.usd.firebase.firestore.FieldValue.arrayUnion(data),
        flag: flag,
      }).then(() => {
        this.msgCount(other, chatId)
      });
    }
  }

  msgCount(otherPart, chatId) {
    console.log('msgCount', otherPart);
    const dd = moment().toString();
    this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).ref.get().then(msgRef => {
      if (msgRef.exists) {
        console.log(msgRef.data(), msgRef.data().msgNo);
        if (msgRef.data().chatId && (msgRef.data().chatId === chatId)) {
          if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
            const total = msgRef.data().msgNo + 1;
            console.log(total);
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'msgNo': total, 'msgUpdate': dd })
            .then(() => console.log('Number increment'));
          } else {
            msgRef.data().msgNo = 1;
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'msgNo': 1, 'msgUpdate': dd }).then(() => console.log('1st Number'));
          }
        } else {
          if (msgRef.data().msgNo && msgRef.data().msgNo >= 0) {
            const total = msgRef.data().msgNo + 1;
            console.log(total);
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({ 'chatId': chatId, 'msgNo': total, 'msgUpdate': dd })
            .then(() => console.log('Number increment'));
          } else {
            msgRef.data().msgNo = 1;
            this.usd.afs.doc(`Users/${otherPart}/contacts/${this.usd.userId}`).update({  'chatId': chatId, 'msgNo': 1, 'msgUpdate': dd })
            .then(() => console.log('1st Number'));
          }
        }
      }
    })
  }

  clearMsgCount(otherPart) {
    console.log(otherPart);
    this.usd.setContact.msgNo = 0
    this.usd.afs.doc(`Users/${this.usd.userId}/contacts/${otherPart}`).update({ 'msgNo': 0 }).then(() => console.log('Msg count Reset'));
  }

  async deleteMessage(chat: Chat, msg: Message) {
    const { id } = await this.usd.acc;
    const ref = this.usd.afs.collection('chats').doc(chat.id);
    if (chat.id === id || msg.id === id) {
      delete msg.user;
      return ref.update({
        messages: this.usd.firebase.firestore.FieldValue.arrayRemove(msg)
      });
    }
  }

  buildChat(chat$: Observable<any>): Observable<any> {
    let chat: any;
    return chat$.pipe(
      switchMap(c => {
        chat = c;
        // Get all users in the chat -> find user data since only id is known
        const ids = Array.from(
          new Set(c.messages.map((message: any) => message.id))
        );
        const users = this.fetchUsers(ids);
        // users
        return users.length ? combineLatest(users) : of([]);
      }),
      map(users => {
        // console.log(users);
        this.usd.chatParts = users;
        this.buildUserDictionary(users);
        // Augment message data with newly fetched user data
        chat.messages = chat.messages.map((message: any) => {
          return {
            ...message,
            createdAt: moment(message.createdAt.toDate()),
            user: this.userDictionary[message.id]
          };
        });
        return chat;
      })
    );
  }

  private buildUserDictionary(users: unknown[]) {
    users.forEach(user => (this.userDictionary[(user as User).id] = user));
  }

  private fetchUsers(ids: unknown[]): Observable<any>[] {
    return ids.map(id => this.usd.afs.doc(`Users/${id}`).valueChanges());
  }

  getUserById(typerId) {
    return this.userDictionary[typerId];
  }

  openLgg(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.open(template, { size: 'lg' })
  }
}
