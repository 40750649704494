import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InitialiseService } from 'app/services/initialise.service';
import { Task, actionActualData, actualData } from 'app/models/task-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDataService } from 'app/services/user-data.service';

/* tools */
import * as moment from 'moment';
import { map, find } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WriteService } from 'app/services/writes.service';
import { Enterprise } from 'app/models/enterprise-model';
import { Project } from 'app/models/project-model';
import { ProjServiceService } from 'app/services/proj-service.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ChatService } from 'app/services/chat.service';
import {
    CdkDragDrop,
    moveItemInArray,
    CdkDrag,
    // CdkDrop,
    CdkDragStart,
    transferArrayItem
} from '@angular/cdk/drag-drop';
declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'app-chat-plugin',
    templateUrl: 'chatPlugin.component.html'
})

export class ChatPluginComponent implements OnInit {
    newTask: Task;
    setFor = '';
    @ViewChild('template1') template1: TemplateRef<any>;
    @ViewChild('template2') template2: TemplateRef<any>;
    modalRef: any;
    modalRef2: any;

    constructor(public is: InitialiseService, public modalService: NgbModal, public usd: UserDataService, public ws: WriteService,
        public ps: ProjServiceService, public afs: AngularFirestore, public cs: ChatService) {
        // this.cs.create(this.gs);
    }

    async gs() {
        return await this.usd?.setContact;
    }


    public openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template);
    }

    openLgg(template: TemplateRef<any>) {
        this.modalRef2 = this.modalService.open(template, { size: 'lg' })
    }

    openModal2(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template, { size: 'lg' });
        this.usd.myDocument.ref.get().then(() => {
            // this.playAudio();
        })
    }

    ngOnInit() {
        const $sidebar = $('.sidebar');
        const $off_canvas_sidebar = $('.off-canvas-sidebar');
        const window_width = window.outerWidth;

        if (window_width > 767) {
            if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
                $('.fixed-plugin .dropdown').addClass('open');
            }
        }

        $('.fixed-plugin a').click(function (event) {
            if ($(this).hasClass('switch-trigger')) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (window.event) {
                    window.event.cancelBubble = true;
                }
            }
        });

        $('.fixed-plugin .background-color span').click(function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            const new_color = $(this).data('color');
            if ($sidebar.length !== 0) {
                $sidebar.attr('data-color', new_color);
            }
            if ($off_canvas_sidebar.length !== 0) {
                $off_canvas_sidebar.attr('data-color', new_color);
            }
        });

        $('.fixed-plugin .active-color span').click(function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            const new_color = $(this).data('color');

            if ($sidebar.length !== 0) {
                $sidebar.attr('data-active-color', new_color);
            }

            if ($off_canvas_sidebar.length !== 0) {
                $off_canvas_sidebar.attr('data-active-color', new_color);
            }
        });
    }
}
