import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items

export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'nc-icon nc-bank'
},  {
    path: '/chats',
    title: 'Chats',
    icontype: 'nc-icon nc-chat-33',
    type: 'link',
}, 
{  
    path: 'chats',
    title: 'Chat',
    type: 'link',
    icontype: 'nc-icon nc-chat-33',
},
{
    path: '/psn-forms',
    title: 'Personal Financials',
    icontype: 'nc-icon nc-chat-33',
    type: 'link',
}, {
    path: '/transactions',
    title: 'Open Books 360°',
    type: 'sub',
    collapse: 'transactions',
    icontype: 'nc-icon nc-layout-11',
    children: [
        {path: 'personal', title: 'Personal', ab: 'H'},
        {path: 'diary', title: 'My Diary', ab: 'H'},
        {path: 'projects', title: 'Projects', ab: 'P'},
        {path: 'project_companies', title: 'Project Companies', ab: 'PC'},
        {path: 'project_team', title: 'Team', ab: 'PT'},
        {path: 'project_dashboard', title: 'Project Dashboard', ab: 'PD'},
        {path: 'enterprise_customers', title: 'Customers', ab: 'PC'},
        {path: 'project-customers', title: 'Customers', ab: 'PC'},
        {path: 'project-supplier', title: 'Project Supplier', ab: 'PC'},
        {path: 'project-inventory', title: 'Project Inventory', ab: 'PI'},
        {path: 'inventory-dashboard', title: 'Project Inventory Dashboard', ab: 'PID'},
        {path: 'customer-managers', title: 'Customer Managers', ab: 'CM'},
        {path: 'customer-dashboard', title: 'Customer Dashboard', ab: 'CD'},
        {path: 'parties-inventory', title: 'Party Inventory', ab: 'PI'},
        {path: 'enterprise_staff', title: 'Staff', ab: 'PT'},
        {path: 'enterprise_dashboard', title: 'Company Dashboard', ab: 'PD'},
        {path: 'project-equipment', title: 'Equipment', ab: 'PE'},
        {path: 'project-description', title: 'Description', ab: 'PD'},
        {path: 'project-standards', title: 'Project Standards', ab: 'PS'},
        {path: 'project-cost-framework', title: 'Cost framework', ab: 'PCF'},
        {path: 'enterprises', title: 'Enterprises', ab: 'E'},
        {path: 'ent-projects', title: 'Enterprise Projects', ab: 'E'},
        {path: 'ent-assets', title: 'Assets', ab: 'E'},
        {path: 'ent-departments', title: 'Departments', ab: 'E'},
        {path: 'ent-standards', title: 'Enterprise Standards', ab: 'E'},
        {path: 'dashboard', title: 'Dashboard', ab: 'D'},
        {path: 'acc-statement-report', title: 'STATEMENT OF ACCOUNT', ab: 'D'},
        /* Ex Views */

        {path: 'chats', title: 'Chat', ab: 'U'},
        {path: 'task', title: 'name task', ab: 'U'},
        {path: 'user', title: 'name user', ab: 'U'},
        {path: 'ent-user', title: 'name ent-user', ab: 'EU'},
        {path: 'department', title: 'Department', ab: 'ED'},
        {path: 'ent-man', title: 'name ent-manager', ab: 'CUM'},
        {path: 'proj-user', title: 'name proj-user', ab: 'PU'},
        {path: 'team-user', title: 'name team-user', ab: 'TU'},
        {path: 'project', title: 'name proj', ab: 'P'},
        {path: 'enterprise', title: 'name corp', ab: 'D'},
        {path: 'ent-customer', title: 'name customer', ab: 'D'},
        {path: 'project-company', title: 'name proj-cmp', ab: 'D'},
        {path: 'company-champions', title: 'Team Champions', ab: 'TC'},
        {path: 'company-dashboard', title: 'Team Dashboard', ab: 'TD'},
        {path: 'home', title: 'Open Books 360°', ab: 'OB'},
        {path: 'invite', title: 'Send Invitation', ab: 'H'},
        {path: 'send-invite', title: 'Customer Invitation', ab: 'H'},
        {path: 'team-invitation', title: 'Team Invitation', ab: 'H'},
        {path: 'tasks', title: 'My Tasks', ab: 'C'},
        {path: 'contacts', title: 'My Contacts', ab: 'C'},
        {path: 'p-dashboard', title: 'My Dashboard', ab: 'D'},
        {path: 'classifications', title: 'My Classifications', ab: 'MC'},
        {path: 'standards', title: 'My Standards', ab: 'MS'},
    ]
}, {
    path: '/task24-7',
    title: 'Task24/7',
    type: 'sub',
    collapse: 'pins',
    icontype: 'nc-icon nc-layout-11',
    children: [
        {path: 'personal', title: 'Personal', ab: 'H'},
        {path: 'diary', title: 'My Diary', ab: 'H'},
        {path: 'projects', title: 'Projects', ab: 'P'},
        {path: 'project_companies', title: 'Project Companies', ab: 'PC'},
        {path: 'project_team', title: 'Team', ab: 'PT'},
        {path: 'project_dashboard', title: 'Project Dashboard', ab: 'PD'},
        {path: 'enterprise_customers', title: 'Customers', ab: 'PC'},
        {path: 'customer-managers', title: 'Customer Managers', ab: 'CM'},
        {path: 'customer-dashboard', title: 'Customer Dashboard', ab: 'CD'},
        {path: 'enterprise_staff', title: 'Staff', ab: 'PT'},
        {path: 'enterprise_dashboard', title: 'Company Dashboard', ab: 'PD'},
        {path: 'project-equipment', title: 'Equipment', ab: 'PE'},
        {path: 'project-description', title: 'Description', ab: 'PD'},
        {path: 'project-standards', title: 'Project Standards', ab: 'PS'},
        {path: 'project-cost-framework', title: 'Cost framework', ab: 'PCF'},
        {path: 'enterprises', title: 'Enterprises', ab: 'E'},
        {path: 'ent-projects', title: 'Enterprise Projects', ab: 'E'},
        {path: 'ent-assets', title: 'Assets', ab: 'E'},
        {path: 'ent-departments', title: 'Departments', ab: 'E'},
        {path: 'ent-standards', title: 'Enterprise Standards', ab: 'E'},
        {path: 'dashboard', title: 'Dashboard', ab: 'D'},
        {path: 'ent-forms', title: 'Enterprise Financials', ab: 'EFS' }, 
        /* Ex Views */

        {path: 'task', title: 'name task', ab: 'U'},
        {path: 'user', title: 'name user', ab: 'U'},
        {path: 'ent-user', title: 'name ent-user', ab: 'EU'},
        {path: 'department', title: 'Department', ab: 'ED'},
        {path: 'ent-man', title: 'name ent-manager', ab: 'CUM'},
        {path: 'proj-user', title: 'name proj-user', ab: 'PU'},
        {path: 'team-user', title: 'name team-user', ab: 'TU'},
        {path: 'project', title: 'name proj', ab: 'P'},
        {path: 'enterprise', title: 'name corp', ab: 'D'},
        {path: 'myprojected-financial', title: 'My projected financials', ab: 'PF'},
        {path: 'projected-financial', title: 'projected-financial', ab: 'PF'},
        {path: 'myfinancial-actuals', title: 'My actual financials', ab: 'FA'},
        {path: 'financial-actuals', title: 'financial-actuals', ab: 'FA'},
        {path: 'ent-customer', title: 'name customer', ab: 'D'},
        {path: 'project-company', title: 'name proj-cmp', ab: 'D'},
        {path: 'company-champions', title: 'Team Champions', ab: 'TC'},
        {path: 'company-dashboard', title: 'Team Dashboard', ab: 'TD'},
        {path: 'home', title: 'My Home', ab: 'H'},
        {path: 'invite', title: 'Send Invitation', ab: 'H'},
        {path: 'send-invite', title: 'Customer Invitation', ab: 'H'},
        {path: 'team-invitation', title: 'Team Invitation', ab: 'H'},
        {path: 'tasks', title: 'My Tasks', ab: 'C'},
        {path: 'contacts', title: 'My Contacts', ab: 'C'},
        {path: 'p-dashboard', title: 'My Dashboard', ab: 'D'},
        {path: 'classifications', title: 'My Classifications', ab: 'MC'},
        {path: 'standards', title: 'My Standards', ab: 'MS'},
    ]
}, {
    path: '/views',
    title: 'Views',
    type: 'sub',
    collapse: 'pins',
    icontype: 'nc-icon nc-layout-11',
    children: [
        {path: '', title: 'Views', ab: 'H'},
        {path: 'report-photofeed', title: 'Photofeed', ab: 'PF'},
        {path: 'report-frappe-gantt', title: 'Frappe Gantt', ab: 'FG'},
        {path: 'report-public-dash', title: 'Public Client Dashboard', ab: 'PCD'},
        {path: 'report-sales-map', title: 'Stands Map', ab: 'SM'},
    ]
}, {
    path: '/components',
    title: 'Components',
    type: 'sub',
    collapse: 'components',
    icontype: 'nc-icon nc-layout-11',
    children: [
        {path: 'buttons', title: 'Buttons', ab: 'B'},
        {path: 'grid', title: 'Grid System', ab: 'GS'},
        {path: 'panels', title: 'Panels', ab: 'P'},
        {path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA'},
        {path: 'notifications', title: 'Notifications', ab: 'N'},
        {path: 'icons', title: 'Icons', ab: 'I'},
        {path: 'typography', title: 'Typography', ab: 'T'}
    ]
}, {
    path: '/forms',
    title: 'Forms',
    type: 'sub',
    collapse: 'forms',
    icontype: 'nc-icon nc-ruler-pencil',
    children: [
        {path: 'regular', title: 'Regular Forms', ab: 'RF'},
        {path: 'extended', title: 'Extended Forms', ab: 'EF'},
        {path: 'validation', title: 'Validation Forms', ab: 'VF'},
        {path: 'wizard', title: 'Wizard', ab: 'W'}
    ]
}, {
    path: '/tables',
    title: 'Tables',
    type: 'sub',
    collapse: 'tables',
    icontype: 'nc-icon nc-single-copy-04',
    children: [
        {path: 'regular', title: 'Regular Tables', ab: 'RT'},
        {path: 'extended', title: 'Extended Tables', ab: 'ET'},
        {path: 'datatables.net', title: 'Datatables.net', ab: 'DT'}
    ]
}, {
    path: '/maps',
    title: 'Maps',
    type: 'sub',
    collapse: 'maps',
    icontype: 'nc-icon nc-pin-3',
    children: [
        {path: 'google', title: 'Google Maps', ab: 'GM'},
        {path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM'},
        {path: 'vector', title: 'Vector Map', ab: 'VM'}
    ]
}, {
    path: '/widgets',
    title: 'Widgets',
    type: 'link',
    icontype: 'nc-icon nc-box'

}, {
    path: '/charts',
    title: 'Charts',
    type: 'sub',
    // type: 'link',
    icontype: 'timeline',
    collapse: 'charts',
    children: [
        {path: 'chartjs', title: 'Chart JS', ab:'CJS'},
        {path: 'jschart-guntt', title: 'JS Chart Gunnt', ab:'JSCG'},
        {path: 'jschart', title: 'JS Charting', ab:'JSC'},
    ]

},{
//     path: '/charts',
//     title: 'Charts',
//     type: 'link',
//     icontype: 'nc-icon nc-chart-bar-32'

// }, {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'nc-icon nc-calendar-60'
}, {
    path: '/pages',
    title: 'Pages',
    collapse: 'pages',
    type: 'sub',
    icontype: 'nc-icon nc-book-bookmark',
    children: [
        {path: 'timeline', title: 'Timeline Page', ab: 'T'},
        {path: 'useri', title: 'User Page', ab: 'UP'},
        {path: 'login', title: 'Login Page', ab: 'LP'},
        {path: 'register', title: 'Register Page', ab: 'RP'},
        {path: 'lock', title: 'Lock Screen Page', ab: 'LSP'}
    ]
}
];

@Component({
    moduleId: module.id,
    // tslint:disable-next-line: component-selector
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    isNotMobileMenu() {
        if ( window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    // tslint:disable-next-line: use-life-cycle-interface
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    // tslint:disable-next-line: use-life-cycle-interface
    ngAfterViewInit() {
    }
}
